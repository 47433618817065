<button
  mat-button
  class="button"
  [ngClass]="buttonStyleType"
  [disabled]="disabled"
  [ngStyle]="style"
  (click)="makePayment(amount)"
>
  <ng-container *ngIf="!spinner">
    Pay {{amount}}$
  </ng-container>
  <app-spinner *ngIf="spinner" [spinnerColor]="spinnerColor" [diameter]="20"></app-spinner>
</button>
