<mat-card class="asset-tab">

  <mat-card-content [formGroup]="form">
    <div class="form-fields-area">
      <div class="left-column">
        <div class="form-field">
          <div class="label">Asset Category</div>
          <span> {{getCategoryNameById(currentAsset.categoryAccountId)}} </span>
        </div>
        <div class="form-field">
          <app-input
            #formField
            [formControl]="form.controls['name']"
            [disableState]="modalMode === 'View'"
            [label]="'Asset Name'"
            [placeholder]="'Enter asset name'"
            [type]="'text'"
            [requiredValidator]="true"
          ></app-input>
        </div>
        <div class="form-field">
          <app-textarea
            #formField
            [label]="'Details'"
            [placeholder]="'Write the details here...'"
            [disableState]="modalMode === 'View'"
            [formControl]="form.controls['details']"
            class="form-field"
          ></app-textarea>
        </div>
      </div>

      <div class="right-column">
        <div class="form-field">
          <div class="label">Asset Identifier</div>
          <span> {{currentAsset.identifier}} </span>
        </div>
        <div class="form-field">
          <div class="label">Book Value (after depreciation)</div>
          <span> ${{currentAsset.bookValue | number: '0.2-2'}} </span>
        </div>
        <div class="form-field">
          <div class="label">Depreciated To</div>
          <span> {{currentAsset.depreciatedTo ? currentAsset.depreciatedTo : 'N/A'}} </span>
        </div>
      </div>
    </div>

    <div class="form-fields-rows">
      <div class="form-fields-row">
        <div class="label">Purchase or Acquisition Details</div>
        <div class="form-fields-wrapper">
          <div class="right">
            <div class="form-field">
              <div class="label">Date Acquired</div>
              <span> {{currentAsset.dateAcquired | date: 'd MMM, y'}} </span>
            </div>
            <div class="form-field">
              <div class="label">Acquisition Type</div>
              <span> {{currentAsset.acquisitionType}} </span>
            </div>
          </div>
          <div class="form-field text-right">
            <div class="label">Value at Acquisition</div>
            <span> ${{currentAsset.valueAtAcquisition}} </span>
          </div>
        </div>
      </div>

      <ng-container *ngIf="(modalMode === 'Edit' && isRegisterState) || currentAsset.status === 'Registered'">
        <div class="form-fields-row">
          <div class="label">Depreciation Settings</div>
          <div class="form-fields-wrapper">
            <div class="form-field">
              <app-select-opt-autocomplete
                [label]="'Depreciation Method'"
                [placeholder]="'Select Depreciation Method'"
                [list]="depreciationMethods"
                [requiredValidator]="true"
                [selectObjectValidator]="true"
                [formControl]="form.controls['depreciationMethod']"
                [disableState]="modalMode === 'View'"
                [transparentBackground]="modalMode === 'View'"
              ></app-select-opt-autocomplete>
            </div>
            <div class="form-field">
              <app-input
                #formField
                [formControl]="form.controls['effectiveLife']"
                [isTextRight]="true"
                [label]="'Effective Life (years)'"
                [placeholder]="'Enter effective life'"
                [type]="'number'"
                [requiredValidator]="true"
                [disableState]="modalMode === 'View'"
                [transparentBackground]="modalMode === 'View'"
              ></app-input>
            </div>
            <div class="form-field">
              <app-input-amount
                [placeholder]="'$0.00'"
                [maxDigits]="9"
                [label]="'Residual Value'"
                [requiredValidator]="true"
                [isTextRight]="true"
                [formControl]="form.controls['residualValue']"
                [disableState]="modalMode === 'View'"
                [transparentBackground]="modalMode === 'View'"
              ></app-input-amount>
            </div>
            <div class="form-field">
              <app-input
                #formField
                [formControl]="form.controls['depreciationRate']"
                [isTextRight]="true"
                [label]="'Depreciation Rate'"
                [placeholder]="'Enter depreciation rate'"
                [type]="'number'"
                [requiredValidator]="true"
                [disableState]="modalMode === 'View'"
                [transparentBackground]="modalMode === 'View'"
              ></app-input>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </mat-card-content>

  <mat-card-actions>
    <div class="left-side">
      <ng-container *ngIf="modalMode === 'View' && currentAsset.status !== 'Registered'">
        <app-button
          [label]="'Register'"
          [buttonStyleType]="'primary'"
          (btnClick)="editButtonClick(); this.isRegisterState = true"
        ></app-button>
      </ng-container>
      <ng-container *ngIf="modalMode === 'View' && currentAsset.status === 'Registered'">
        <app-button
          [label]="'Sell or Dispose of Asset'"
          [buttonStyleType]="'bg-white border-purple'"
        ></app-button>
      </ng-container>
    </div>

    <div class="right-side">
      <ng-container *ngIf="modalMode !== 'View'">
        <app-button
          [label]="isRegisterState ? 'Register' : 'Save'"
          [buttonStyleType]="'primary'"
          [spinner]="spinner$ | async"
          (btnClick)="saveButtonClick()"
        ></app-button>
        <app-button
          [label]="'Cancel'"
          [buttonStyleType]="'black'"
          (btnClick)="cancelButtonClick()"
        ></app-button>
      </ng-container>

      <ng-container *ngIf="modalMode === 'View'">
        <app-button
          [label]="'Edit'"
          [buttonStyleType]="'primary'"
          (btnClick)="editButtonClick()"
        ></app-button>
        <app-button
          [label]="'Close'"
          [buttonStyleType]="'black'"
          (btnClick)="cancelButtonClick()"
        ></app-button>
      </ng-container>
    </div>
  </mat-card-actions>

</mat-card>
