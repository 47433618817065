<div class="table mobile-hidden">

  <div class="table-header">
    <app-select-opt-group-autocomplete
      placeholder="Bank Account:"
      [formControl]="bankAccountFormControl"
      [requiredValidator]="true"
      [setList]="bankAccountsList"
    >
    </app-select-opt-group-autocomplete>
    <ng-container *ngIf="!file">
      <app-input-file
        [label]="'Add File'"
        [buttonStyleType]="'primary'"
        (file)="file = $event"
      ></app-input-file>
      <app-dropzone-file
        [label]="'Or drop .QIF file here'"
        (file)="file = $event"
      ></app-dropzone-file>
    </ng-container>

    <ng-container *ngIf="file">
      <div class="imported">
        <img class="imported-file" src="/assets/icons/file.svg" alt="File">
        <div class="imported-name">{{ file.name }}</div>
        <app-close-btn (btnClick)="file = null"></app-close-btn>
      </div>
    </ng-container>

    <app-button
      [label]="'Import'"
      [buttonStyleType]="'primary'"
      [disabled]="!(file && this.bankAccountFormControl.valid)"
      [spinner]="spinner$ | async"
      (btnClick)="importFile()"
    ></app-button>
  </div>

  <div class="table-content bank-feeds-table-wrapper">

    <ng-container *ngIf="!dataLoaded">
      <ngx-skeleton-loader [theme]="{height: '50px', borderRadius: '5px'}"></ngx-skeleton-loader>
      <div class="skeleton-table-row" *ngFor="let item of [].constructor(9)">
        <ngx-skeleton-loader [theme]="{height: '16px', width: '16px', borderRadius: '200px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '16px', width: '76px', borderRadius: '200px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '16px', width: '76px', borderRadius: '200px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '16px', width: '76px', borderRadius: '200px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '16px', width: '136px', borderRadius: '200px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '16px', width: '160 px', borderRadius: '200px'}"></ngx-skeleton-loader>
      </div>
    </ng-container>

    <ng-container *ngIf="dataLoaded && dataSource.data.length; else empty">
      <table
        mat-table
        matSort
        multiTemplateDataRows
        [dataSource]="dataSource"
        class="bank-feeds"
      >

        <ng-container
          matColumnDef="{{column}}"
          *ngFor="let column of columns"
        >
          <ng-container *ngIf="column === 'dropDown'; else primaryColumn">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <div class="image"></div>
            </td>
          </ng-container>
          <!--      Date Column       -->
          <ng-container *ngIf="column === 'date'; else primaryColumn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'Date' }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="td-container">
                <p>
                  {{ element.date | date: 'd MMM, yyyy' }}
                </p>
              </div>
            </td>
          </ng-container>

          <!--      Status Column       -->
          <ng-container *ngIf="column === 'status'; else primaryColumn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'Status' }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="td-container">
                <app-chip [label]="element.status"></app-chip>
              </div>
            </td>
          </ng-container>

          <!--      Payee Column       -->
          <ng-container *ngIf="column === 'payee'; else primaryColumn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'Payee' }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="td-container">
                <p>
                  {{ element.payee }}
                </p>
              </div>
            </td>
          </ng-container>

          <!--      Memo Column       -->
          <ng-container *ngIf="column === 'memo'; else primaryColumn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'Memo' }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="td-container">
                <p>
                  {{ element.memo }}
                </p>
              </div>
            </td>
          </ng-container>

          <!--      Num Column       -->
          <ng-container *ngIf="column === 'num'; else primaryColumn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'Num' }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="td-container">
                <p>
                  {{ element.num }}
                </p>
              </div>
            </td>
          </ng-container>

          <!--      Amount Column       -->
          <ng-container *ngIf="column === 'amount'; else primaryColumn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'Amount' }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="td-container">
                {{ element.amount }}
              </div>
            </td>
          </ng-container>

          <ng-template #primaryColumn>
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ getColumnName(column) }}
            </th>
            <td mat-cell *matCellDef="let element">
              <p appEllipsisTooltip>
                {{ element[column] }}
              </p>
            </td>
          </ng-template>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columns.length"
              [class.expanded]="element == expandedElement">
            <div class="drop-down-item-wrap"
                 [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
            >
              <div
                class="drop-down-item"
                *ngIf="element == expandedElement"
              >
                <div class="selects-box">
                  <app-select-opt
                    label="Type:"
                    placeholder="Type:"
                    [formControl]="this.itemFormGroup.get('type')"
                    [list]="typeListData"
                    [requiredValidator]="true"
                  ></app-select-opt>
                  <app-select-opt-group-autocomplete
                    label="Accounts:"
                    placeholder="Accounts:"
                    [formControl]="this.itemFormGroup.get('accounts')"
                    [requiredValidator]="true"
                    [setList]="accountTypesList"
                  >
                  </app-select-opt-group-autocomplete>
                </div>
                <div class="btns-box">
                  <app-button
                    buttonStyleType="primary"
                    label="Reconcile"
                    [disabled]="this.itemFormGroup.invalid"
                    [style]="{'min-width': '120px'}"
                    [spinner]="loadingService.loading$ | async"
                    (btnClick)="reconcileFeed(element.id)"
                  >
                  </app-button>
                  <app-button
                    buttonStyleType="secondary"
                    label="Cancel"
                    [disabled]="this.itemFormGroup.pristine"
                    [style]="{'min-width': '120px'}"
                    (btnClick)="resetForm()"
                  >
                  </app-button>
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns" [class.hidden]="!dataLoaded"></tr>

        <tr
          mat-row
          class="element-row"
          *matRowDef="let element; columns: columns;"
          [class.border-hidden]="!dataLoaded"
          [class.expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element; resetForm()">
          >
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
      </table>

      <mat-paginator
        [class.hidden]="!dataSource || (dataSource && dataSource.data.length <= pageSize)"
        [pageSize]="pageSize"
      ></mat-paginator>
    </ng-container>

    <ng-template #empty>
      <app-empty-content
        [emptyContentData]="emptyContentBankFeed"
      ></app-empty-content>
    </ng-template>
  </div>

</div>
