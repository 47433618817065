<div class="page-wrapper">
  <div class="page-title mobile-hidden">
    <div *ngIf="!(dataLoaded$ | async)" class="skeleton-loader">
      <ngx-skeleton-loader animation="false"></ngx-skeleton-loader>
    </div>
    <ng-container *ngIf="dataLoaded$ | async">
      Bank Feeds
    </ng-container>
  </div>
  <div class="page-content mobile-hidden">
    <app-bank-feeds-table
      [dataLoaded]="dataLoaded$ | async"
      [bankFeedsItems$]="bankFeedsItems$"
    ></app-bank-feeds-table>
  </div>
</div>
