<div class="success-page">
  <div class="logo"><img src="../../../../assets/images/logo-color.svg" alt="logo"></div>
  <mat-card class="success-card">
    <div class="image-wrap">
      <div class="circle">
        <img src="../../../../assets/icons/check-your-email.svg" alt="success">
      </div>
    </div>
    <mat-card-header>
      <mat-card-title>
        Check your email
      </mat-card-title>
      <mat-card-subtitle>
        We have sent a password recovery
        instructions to your email.
      </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <app-button label="Resend Email" buttonStyleType="primary full" (btnClick)="clickButton()"></app-button>

      <div class="card-bottom-text">Ready to sign in?
        <mat-label class="nav" [routerLink]="['/auth/sign-in']">Sign in here</mat-label>
      </div>

      <div class="text">
        If you have not received the email after a few minutes.
        Please check your spam folder.
      </div>
    </mat-card-content>
  </mat-card>
</div>
