<div class="header-wrapper">
  <div class="trigger">
    <img
      src="../../../../assets/icons/trigger.svg"
      alt="trigger"
      (click)="toggleSideBar.emit()"
    >
  </div>
  <div class="panel">
    <app-user-panel></app-user-panel>
  </div>
</div>
