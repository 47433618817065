import { BankFeedsModel } from '../app/core/interfaces/bank-feeds.interface';

export let mockBankFeedsData: BankFeedsModel[] = [
  {
    amount: 2,
    date: '2023-10-20',
    id: 'hafefad-sk138523',
    memo: 'test',
    num: 'test',
    payee: 'test',
    status: 'PAID'
  },
  {
    amount: 3,
    date: '2023-11-20',
    id: 'fdhfhf-sk138523',
    memo: 'test 1',
    num: 'test 1',
    payee: 'test 1',
    status: 'PAID'
  }
];

export const removeFeed = (orgId: string): void => {
  mockBankFeedsData = mockBankFeedsData.filter(item => item.id !== orgId);
};
