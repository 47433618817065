<div class="ledgers-tab-container">
  <ng-container *ngIf="(dataLoaded$ | async) && dataSource.data.length > 0; else noFoundData">
    <ng-container *ngIf="(dataLoaded$ | async)">
      <table
        mat-table
        [dataSource]="dataSource"
        class="ledgers-table"
        matSort

        #empTbSort="matSort"
      >
        <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">

          <ng-container *ngIf="column === 'date'">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">
              <div class="td-container">
                {{element[column] | date: 'd MMM, y'}}
              </div>
            </td>
          </ng-container>

          <ng-container *ngIf="column === 'account'">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Account</th>
            <td mat-cell *matCellDef="let element">
              <div  class="td-container">
                <p appEllipsisTooltip>
                  {{element[column]?.name}}
                </p>
              </div>
            </td>
          </ng-container>

          <ng-container *ngIf="column === 'party'">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Party</th>
            <td mat-cell *matCellDef="let element">
              <div class="td-container">
                <p appEllipsisTooltip>
                  {{element[column]}}
                </p>
              </div>
            </td>
          </ng-container>

          <ng-container *ngIf="column === 'debit'">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Debit</th>
            <td mat-cell *matCellDef="let element">
              <div class="td-container">
                ${{element[column]}}
              </div>
            </td>
          </ng-container>

          <ng-container *ngIf="column === 'credit'">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Credit</th>
            <td mat-cell *matCellDef="let element">
              <div class="td-container">
                ${{element[column]}}
              </div>
            </td>
          </ng-container>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openModal(row)"></tr>
      </table>
    </ng-container>
  </ng-container>

  <ng-template #noFoundData>
    <app-empty-content
      [emptyContentData]="emptyContentLedgersTab"
    ></app-empty-content>
  </ng-template>
</div>
