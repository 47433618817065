<div class="success-page">
  <div class="logo"><img src="../../../../assets/images/logo-color.svg" alt="logo"></div>
  <mat-card class="success-card">
    <div class="image-wrap">
      <div class="circle">
        <img src="../../../../assets/icons/check-all.svg" alt="success">
      </div>
    </div>
    <mat-card-header>
      <mat-card-title>
        You’ve got yourself a
        new password
      </mat-card-title>
      <mat-card-subtitle>
        Your password has been changed successfully.
      </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <app-button label="Sign in now!" buttonStyleType="primary full" (btnClick)="clickButton()"></app-button>

    </mat-card-content>
  </mat-card>
</div>
