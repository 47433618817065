<div class="table mobile-hidden">

  <app-table-header-actions
    [addButtonName]="'Create Invoice'"
    [addButtonType]="'primary'"
    [dataLoaded]="dataLoaded"
    [filterValue]="filterValue"
    (applyFilter)="applyFilter($event)"
    (handleAction)="createInvoice.emit($event)"
  ></app-table-header-actions>

  <ng-container *ngIf="!dataLoaded">
    <ngx-skeleton-loader [theme]="{height: '50px', borderRadius: '5px'}"></ngx-skeleton-loader>
    <div class="skeleton-table-row" *ngFor="let item of skeletonRows">
      <ngx-skeleton-loader [theme]="{height: '16px', width: '100px', borderRadius: '200px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{height: '16px', width: '100px', borderRadius: '200px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{height: '16px', width: '100px', borderRadius: '200px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{height: '16px', width: '100px', borderRadius: '200px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{height: '16px', width: '100px', borderRadius: '200px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{height: '16px', width: '100px', borderRadius: '200px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{height: '16px', width: '24px', borderRadius: '200px'}"></ngx-skeleton-loader>
    </div>
  </ng-container>

  <ng-container *ngIf="dataLoaded">
    <div class="table-content invoices-table-wrapper">
      <table
        mat-table
        matSort
        matSortActive="date"
        matSortDirection="desc"
        [dataSource]="dataSource"
      >

        <!-- Date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Date' }}
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="td-container"><p>{{ row.date | date: 'd MMM, y'}}</p></div>
          </td>
        </ng-container>

        <!-- Invoice № Column -->
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Invoice No.' }}
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="td-container"><p>{{ row.number }}</p></div>
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Status' }}
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="td-container">
              <ng-container *ngIf="row.status">
                <app-chip [label]="row.status"></app-chip>
              </ng-container>
            </div>
          </td>
        </ng-container>

        <!-- Party Column -->
        <ng-container matColumnDef="party">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Party' }}
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="td-container">
              <p appEllipsisTooltip>{{ getClientNameById(row.clientId) }}</p>
            </div>
          </td>
        </ng-container>

        <!-- Amount Column -->
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Amount' }}
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="td-container"><p>${{ row.amount | number: '0.2-2' }}</p></div>
          </td>
        </ng-container>

        <!-- Paid date Column -->
        <ng-container matColumnDef="paidDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="paidDate-column">
            {{ 'Paid date' }}
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="td-container"><p>{{ row?.paidDate | date: 'd MMM, y' }}</p></div>
          </td>
        </ng-container>

        <!-- More Column -->
        <ng-container matColumnDef="more">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <div class="td-container">
              <app-table-row-actions
                *ngIf="dataLoaded"
                [actions]="actions"
                (handleAction)="handleAction.emit([row, $event])"
              ></app-table-row-actions>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns" [class.hidden]="!dataLoaded"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;" (click)="handleAction.emit([row, 'view'])"></tr>
      </table>

      <mat-paginator
        [class.hidden]="!dataSource || (dataSource && dataSource.data.length <= pageSize)"
        [pageSize]="pageSize"
      ></mat-paginator>
    </div>
  </ng-container>
</div>
