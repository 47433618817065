import { ISelectListItem } from '../../shared/interfaces/select-control.interface';

export const bankFeedsTableColumn: string[] = [
  'dropDown',
  'date',
  'status',
  'payee',
  'memo',
  'num',
  'amount'
];

export const bankFeedType: ISelectListItem<string>[] = [
  {
    displayName: 'Expense', value: 'Expense'
  },
  {
    displayName: 'Cash', value: 'Cash'
  }
];
