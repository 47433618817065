<mat-card class="modal-transaction-list">
  <mat-card-header>
    <mat-card-title>
      {{ data.title }}
    </mat-card-title>
    <app-close-btn class="close" (btnClick)="dialogRef.close()"></app-close-btn>
  </mat-card-header>
  <mat-card-content>

    <app-table-header-actions
      [addButtonType]="'primary'"
      [addButtonName]="data.buttonName"
      [dataLoaded]="true"
      [filterValue]="filterValue"
      (applyFilter)="applyFilter($event)"
      (handleAction)="addNew()"
    ></app-table-header-actions>

    <div class="table-container">
      <ng-container *ngIf="dataSource.data.length">
        <table mat-table [dataSource]="dataSource" class="invoice-table">
          <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef>
              <div class="th-container">{{ getNumberColumnName() + ' No.'}}</div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="td-container"> {{ element.invoice.number }} </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>
              <div class="th-container"> Description </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="td-container"> {{ element.details }} </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>
              <div class="th-container"> Date </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="td-container"> {{ element.date | date: 'd MMM, y' }} </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="dueDate">
            <th mat-header-cell *matHeaderCellDef>
              <div class="th-container"> Due Date </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="td-container"> {{ element.invoice.dueDate | date: 'd MMM, y' }} </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>
              <div class="th-container"> Amount </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="td-container"> ${{element.amount}} </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columns"></tr>
          <tr mat-row *matRowDef="let row; columns: columns;" (click)="clickRow(row)" class="cursor-pointer"></tr>
        </table>
      </ng-container>

      <ng-container *ngIf="!dataSource.data.length">
        <app-empty-content
          [title]="'You have no unpaid ' + data.title.toLowerCase()"
        ></app-empty-content>
      </ng-container>
    </div>

  </mat-card-content>
</mat-card>
