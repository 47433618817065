<div class="modal-invoice-send-container">
  <div class="modal-invoice-send-header">
    <span>Send Invoice</span>
  </div>

  <div class="modal-invoice-send-body" [formGroup]="sendFormGroup">
    <div class="form-field-wrapper">
      <app-input
          #formField
          maxLength="80"
          requiredValidator="true"
          formControlName="destination"
          label="To"
          type="text"
          placeholder="To"
      ></app-input>
    </div>

    <div class="form-field-wrapper">
      <app-input
          #formField
          maxLength="80"
          requiredValidator="true"
          formControlName="subject"
          label="Subject"
          type="text"
          placeholder="Subject"
      ></app-input>
    </div>

    <div class="form-field-wrapper">
      <app-textarea
          #formField
          maxLength="240"
          [style]="{'height': '100px'}"
          formControlName="message"
          label="Message"
          placeholder="Message"
      ></app-textarea>
    </div>

    <div class="form-field-wrapper">
      <app-checkbox
        label="cc me"
        labelPosition="after"
        formControlName="copyToMe"
      ></app-checkbox>
    </div>
  </div>

  <div class="modal-invoice-send-footer">
    <app-button
      label="Cancel"
      buttonStyleType="black"
      (click)="close()"
    ></app-button>
    <app-button
      buttonStyleType="primary"
      label="Send"
      [spinner]="spinner$ | async"
      [disabled]="sendFormGroup.invalid"
      (btnClick)="submit()"
    ></app-button>
  </div>
</div>
