<div class="chart-card">
  <apx-chart
    [series]="chartSettings?.series"
    [chart]="chartSettings?.chart"
    [xaxis]="chartSettings?.xaxis"
    [yaxis]="chartSettings?.yaxis"
    [colors]="chartSettings?.colors"
    [markers]="chartSettings?.markers"
    [title]="chartSettings?.title"
    [legend]="chartSettings?.legend"
    [plotOptions]="chartSettings?.plotOptions"
    [labels]="chartSettings?.labels"
  ></apx-chart>
</div>
