import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { bankFeedsTableColumn, bankFeedType } from '../../core/constants/bank-feeds.constants';
import { BankFeedsModel, ReconcileFeed } from '../../core/interfaces/bank-feeds.interface';
import { IBankFeedsState } from '../../core/store/bank-feeds/bank-feeds.reducer';
import * as bankFeedsSelectors from '../../core/store/bank-feeds/bank-feeds.selectors';
import * as bankFeedsActions from '../../core/store/bank-feeds/bank-feeds.actions';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { IAccountsState } from '../../core/store/accounts/accounts.reducer';
import { AccountModel, AccountTypeModel } from '../../core/interfaces/account.interface';
import * as accountsSelectors from '../../core/store/accounts/accounts.selectors';
import { ISelectListGroup, ISelectListItem } from '../../shared/interfaces/select-control.interface';
import { LoadingService } from '../../core/services/loading.service';
import { emptyContentBankFeed } from '../../core/constants/empty-content.constant';
import { EmptyContentModel } from '../../core/interfaces/empty-content.interface';

@Component({
  selector: 'app-bank-feeds-table',
  templateUrl: './bank-feeds-table.component.html',
  styleUrls: ['./bank-feeds-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BankFeedsTableComponent implements OnInit, OnDestroy {

  @Input() dataLoaded: boolean;
  @Input() bankFeedsItems$: Observable<BankFeedsModel[]>;

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  subscription$ = new Subject();
  spinner$ = this.bankFeedsStore.select(bankFeedsSelectors.selectIsSpinnerStarted);
  columns: string[] = bankFeedsTableColumn;
  dataSource = new MatTableDataSource<BankFeedsModel>();
  file: File;
  pageSize = 50;
  expandedElement: any;
  accountTypesList: ISelectListGroup<AccountModel[]>[];
  bankAccountsList: ISelectListGroup<AccountModel[]>[];
  typeListData: ISelectListItem<string>[] = bankFeedType;
  bankAccountFormControl: FormControl = this.fb.control(null, Validators.required);
  itemFormGroup = this.fb.group({
    type: this.fb.control(null, Validators.required),
    accounts: this.fb.control(null, Validators.required)
  });
  emptyContentBankFeed: EmptyContentModel = emptyContentBankFeed;

  accounts$: Observable<AccountModel[]> = this.accountsStore.select(accountsSelectors.selectAccountsData)
    .pipe(filter(res => !!res && !!res.length));
  accountTypes$: Observable<AccountTypeModel[]> = this.accountsStore.select(accountsSelectors.selectAccountTypes)
    .pipe(filter(res => !!res && !!res.length));

  constructor(
    private bankFeedsStore: Store<IBankFeedsState>,
    private accountsStore: Store<IAccountsState>,
    private fb: FormBuilder,
    public loadingService: LoadingService
  ) {
  }

  ngOnInit(): void {
    this.bankFeedsItems$
      .pipe(takeUntil(this.subscription$), filter(res => Boolean(res)))
      .subscribe((bankFeeds: BankFeedsModel[]) => {
        this.dataSource.data = bankFeeds;
      });

    combineLatest([this.accounts$, this.accountTypes$])
      .pipe(takeUntil(this.subscription$))
      .subscribe(([accounts, accountTypes]) => {
        if (accountTypes && accounts) {
          this.accountTypesList = accountTypes.map(type => ({
            displayName: type.name,
            children: accounts.filter(acc => acc.accountTypeId === type.id).map((data) => {
              return {
                ...data,
                selected: false
              };
            })
          }));
          this.bankAccountsList = accountTypes.map(type => {
            const filteredAccounts = accounts.filter(acc => acc.accountTypeId === type.id && acc.isCash);
            if (filteredAccounts.length) {
              return {
                displayName: type.name,
                children: accounts.filter(acc => acc.accountTypeId === type.id && acc.isCash).map((data) => {
                  return {
                    ...data,
                    selected: false
                  };
                })
              };
            }
            return null;
          }).filter(item => item !== null);
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription$.next();
    this.subscription$.complete();
  }

  getColumnName(column: string): string {
    return this.columns[column];
  }

  importFile(): void {
    const file = new FormData();
    file.append(this.file.name, this.file);

    this.bankFeedsStore.dispatch(bankFeedsActions.importBankFeeds({ file, accountId: this.bankAccountFormControl.value.accountTypeId }));
  }

  resetForm(): void {
    this.itemFormGroup.reset();
    this.itemFormGroup.markAsPristine();
  }

  reconcileFeed(elementId: string): void {
    const formData = this.itemFormGroup.value;
    const body: ReconcileFeed = {
      type: formData.type,
      accountId: formData.accounts.accountTypeId,
      unreconciledTransactionId: elementId
    };
    this.bankFeedsStore.dispatch(bankFeedsActions.reconcileBankFeed({ body }));
  }
}
