import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loading$: Subject<boolean> = new Subject();

  constructor() {
  }

  setLoading(value: boolean): void {
    this.loading$.next(value);
  }
}
