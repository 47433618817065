import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IInvoicesState } from '../../core/store/invoices/invoices.reducer';
import * as invoicesSelectors from '../../core/store/invoices/invoices.selectors';
import * as invoicesActions from '../../core/store/invoices/invoices.actions';
import { InvoiceModel, SendApprovedInvoiceDto } from '../../core/interfaces/invoice.interface';
import { ModalInvoiceService } from '../../core/services/state/invoices/modal-invoice.service';


@Component({
  selector: 'app-modal-invoice-send',
  templateUrl: './modal-invoice-send.component.html',
  styleUrls: ['./modal-invoice-send.component.scss']
})
export class ModalInvoiceSendComponent implements OnInit, OnDestroy {
  sendFormGroup: FormGroup;
  subscription: Subscription = new Subscription();
  currentInvoice: InvoiceModel;

  spinner$ = this.invoicesStore.select(invoicesSelectors.selectIsSpinnerStarted);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalInvoiceSendComponent>,
    private fb: FormBuilder,
    private invoicesStore: Store<IInvoicesState>,
    public modalInvoiceService: ModalInvoiceService,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.subscription.add(
      this.modalInvoiceService.currentInvoice$.subscribe((invoice) => {
        this.currentInvoice = invoice;
        this.initSendFormGroup();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initSendFormGroup(): void {
    this.sendFormGroup = this.fb.group({
      destination: this.fb.control('', Validators.required),
      subject: this.fb.control('', Validators.required),
      message: this.fb.control(''),
      copyToMe: this.fb.control(false)
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  submit(): void {
    if (this.sendFormGroup.invalid) return;
    const body: SendApprovedInvoiceDto = this.sendFormGroup.getRawValue();
    this.invoicesStore.dispatch(invoicesActions.sendApprovedInvoice({ id: this.currentInvoice.id, body }));
    this.subscription.add(this.spinner$.subscribe( (res) => {
      if (!res) this.dialogRef.close();
    }));
  }
}
