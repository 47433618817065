<div class="auth-form change-password">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Create new password
      </mat-card-title>
    </mat-card-header>

    <mat-card-content class="change-password" [formGroup]="form" (keydown.enter)="sendForm()">

      <div class="form-field-wrapper form-description change-password">
        Your new password must be different from
        previously used passwords
      </div>

      <div class="form-field-wrapper">
        <app-input
          label="Password"
          placeholder="Must be at least 8 characters"
          formControlName="password"
          minLengthValidator="8"
          [type]="'password'"
          [hintSuccess]="'Your password is great. Nice work!'"
          [hintError]="'This value is required.'"
        ></app-input>
      </div>

      <div class="form-field-wrapper">
        <app-input
          label="Confirm Password"
          placeholder="Both password must match"
          formControlName="confirmPassword"
          [mustMatchValidator]="form.get('password')"
          [type]="'password'"
          [hintSuccess]="'Nice work! Passwords are matched'"
          [hintError]="'Both password must match'"
        ></app-input>
      </div>

      <app-button label="Reset Password" buttonStyleType="primary full" (btnClick)="sendForm()"></app-button>
    </mat-card-content>
  </mat-card>

  <div class="under-card-text mobile-hidden">Don’t have an account?
    <mat-label class="nav" [routerLink]="['/auth/sign-up']">Sign up now</mat-label>
  </div>
</div>

