<table mat-table [dataSource]="data">

  <ng-container matColumnDef="{{column}}" *ngFor="let column of columns">

    <ng-container *ngIf="column === 'accountType'; else primaryColumn">
      <th mat-header-cell *matHeaderCellDef>{{ getColumnName(column) }}</th>
      <td mat-cell *matCellDef="let element">
        <p>
          {{ element[column].name }}
        </p>
      </td>
    </ng-container>

    <ng-container *ngIf="column === 'description'; else primaryColumn">
      <th mat-header-cell *matHeaderCellDef>{{ getColumnName(column) }}</th>
      <td mat-cell *matCellDef="let element">
        <p appEllipsisTooltip>
          {{ element[column] }}
        </p>
      </td>
    </ng-container>

    <ng-container *ngIf="column === 'more'; else primaryColumn">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="!element.isSystem">
          <app-table-row-actions
            [actions]="actions"
            (handleAction)="handleAction($event, element)"
          ></app-table-row-actions>
        </ng-container>
      </td>
    </ng-container>

    <ng-container *ngIf="column === 'name'; else primaryColumn">
      <th mat-header-cell *matHeaderCellDef>{{ getColumnName(column) }}</th>
      <td mat-cell *matCellDef="let element">
        <p appEllipsisTooltip>
          {{ element[column] }}
          <ng-container *ngIf="element?.isSystem">
            <img src="../../../assets/icons/lock.svg" alt="lock">
          </ng-container>
        </p>
      </td>
    </ng-container>

    <ng-template #primaryColumn>
      <th mat-header-cell *matHeaderCellDef> {{ getColumnName(column) }} </th>
      <td mat-cell *matCellDef="let element"> {{ element[column] }} </td>
    </ng-template>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let element; columns: columns;" (click)="rowClick(element)"></tr>
</table>
