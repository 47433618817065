<div class="page-wrapper">

<!--  Desktop-->
  <div class="page-title mobile-hidden">
    <div *ngIf="!(dataLoaded$ | async)" class="skeleton-loader">
      <ngx-skeleton-loader animation="false"></ngx-skeleton-loader>
    </div>
    <ng-container *ngIf="dataLoaded$ | async">
      Transactions
    </ng-container>
  </div>
  <div class="page-content mobile-hidden">
    <app-transactions-table
      class="mobile-hidden"
      [transactionsData$]="transactions$"
      [columnsData]="tableColumnsData"
      [dataLoaded$]="dataLoaded$"
      [actions]="transactionsActions"
      [accounts$]="accounts$"
      (addTransaction)="transactionActionService.addTransaction($event)"
      (handleAction)="handleAction($event)"
      (exportToCsv)="exportToCsv()"
    ></app-transactions-table>
  </div>

<!--  Mobile-->
  <app-mobile-transactions-list
    class="mobile-visible"
    [transactions$]="transactions$"
  ></app-mobile-transactions-list>

</div>
