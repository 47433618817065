export const settingLinks = [
  {
    title: 'Profile',
    url: 'profile'
  },
  {
    title: 'Organization',
    url: 'organization'
  },
  {
    title: 'User',
    url: 'user'
  },
  {
    title: 'Invoicing',
    url: 'invoicing'
  },
  {
    title: 'Accounts',
    url: 'accounts'
  },
  {
    title: 'Price Book',
    url: 'price-book'
  }
];
