<mat-card class="ledger-tab">

  <mat-card-content [formGroup]="form">
    <div class="form-fields-area">
      <div class="left-column">
        <ng-container *ngIf="modalMode !== 'View' || !(modalMode === 'View' && !form.controls['party'].value)">
          <div class="form-field party">
            <app-input
              #formField
              [label]="'Other Party'"
              [type]="'text'"
              [placeholder]="'John Doe'"
              [formControl]="form.controls['party']"
              [disableState]="modalMode === 'View'"
            ></app-input>
          </div>
        </ng-container>

        <div class="form-field">
          <app-textarea
            #formField
            [label]="'Details'"
            requiredValidator="true"
            [placeholder]="'Write the details here...'"
            [formControl]="form.controls['details']"
            [style]="modalMode === 'View' ? null : { 'height': '60px'}"
            [disableState]="modalMode === 'View'"
          ></app-textarea>
        </div>
      </div>

      <div class="right-column">
        <div class="form-field">
          <app-date-picker
            #formField
            [label]="'Date'"
            [requiredValidator]="true"
            [placeholder]="'dd/mm/yyyy'"
            [formControl]="form.controls['date']"
            [isTextRight]="modalMode === 'View'"
            [disableState]="modalMode === 'View'"
          ></app-date-picker>
      </div>
        <ng-container *ngIf="modalMode !== 'View' || !(modalMode === 'View' && !form.controls['ref'].value)">
          <div class="form-field">
            <app-input
              #formField
              [label]="'Reference'"
              [type]="'text'"
              [formControl]="form.controls['ref']"
              [placeholder]="'Reference'"
              [isTextRight]="modalMode === 'View'"
              [disableState]="modalMode === 'View'"
            ></app-input>
          </div>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="transactionLedgerItemsIsLoading$ | async">
      <div class="table-loading-wrapper">
        <ngx-skeleton-loader count="4" [ngStyle]="{'display': 'flex', 'margin-bottom': '5px'}" [theme]="{margin: '0 15px 15px', height: '35px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [ngStyle]="{'display': 'flex', 'margin-bottom': '5px'}" [theme]="{margin: '0 15px 15px', height: '42px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [ngStyle]="{'display': 'flex', 'margin-bottom': '5px'}" [theme]="{margin: '0 15px 15px', height: '42px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [ngStyle]="{'display': 'flex', 'margin-bottom': '5px'}" [theme]="{margin: '0 15px 15px', height: '42px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [ngStyle]="{'display': 'flex', 'margin-bottom': '12px'}" [theme]="{margin: '0 15px 15px', height: '42px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="3" [ngStyle]="{'display': 'flex', 'justify-content': 'flex-end', 'margin-bottom': '20px'}" [theme]="{margin: '0 10px 15px', width: '120px', height: '40px'}"></ngx-skeleton-loader>
      </div>
    </ng-container>

    <div class="table-wrapper" *ngIf="!(transactionLedgerItemsIsLoading$ | async)">
      <app-editable-table
          #editableTable
          [columns]="tableColumns"
          [requiredColumns]="tableColumns"
          [groupedAccounts]="groupedAccounts"
          [accounts]="accounts"
          [modalType]="modalMode"
          [class]="'ledger-table'"
          [formGroupRows$]="tableFormGroupRows$"
          [ledgerDate]="this.form.get('date').value"
          (changedLines)="setTransactionLines($event)"
          (linesIsValid)="tableIsValid = $event"
      ></app-editable-table>

      <div class="under-table-row">
        <div class="button-field">
          <app-button
            *ngIf="modalMode !== 'View'"
            label="Add"
            buttonStyleType="add"
            [style]="{'height': '20px'}"
            (btnClick)="editableTable.addLedgerLineRow()"
          ></app-button>
        </div>

        <div class="amount-field">
          <div class="label">Total</div>
          <div class="value-area">
            <div class="amount-area">
              <span class="debit-field"> ${{ debit | number: '0.2-2' }} </span>
              <span class="credit-field"> ${{ credit | number: '0.2-2' }} </span>
            </div>
            <div *ngIf="debit !== credit" class="hint-message">
              <img src="../../../assets/icons/info-red.svg" alt="info">
              <span>Debit should equal credits</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>

  <mat-card-actions>
    <div class="left-side">
      <ng-container *ngIf="modalMode === 'Edit'">
        <app-button
          [label]="'Delete'"
          [buttonStyleType]="'delete bg-white'"
          (btnClick)="clickDeleteButton()"
        ></app-button>
      </ng-container>
    </div>

    <div class="right-side">
      <ng-container *ngIf="modalMode === 'Add'">
        <app-button
          [label]="'Save'"
          [buttonStyleType]="'primary'"
          [spinner]="spinner$ | async"
          [disabled]="!isValid()"
          (btnClick)="clickSaveButton()"
        ></app-button>

        <app-button
          [label]="'Cancel'"
          [buttonStyleType]="'black'"
          (btnClick)="clickCancelButton()"
        ></app-button>
      </ng-container>

      <ng-container *ngIf="modalMode === 'Edit'">
        <app-button
          [label]="'Save'"
          [spinner]="spinner$ | async"
          [buttonStyleType]="'primary'"
          [disabled]="!isValid()"
          (btnClick)="clickSaveButton()"
        ></app-button>

        <app-button
          [label]="'Cancel'"
          [buttonStyleType]="'black'"
          (btnClick)="clickCancelButton()"
        ></app-button>
      </ng-container>

      <ng-container *ngIf="modalMode === 'View'">
        <app-button
          [label]="'Edit'"
          [buttonStyleType]="'primary'"
          (btnClick)="clickEditButton()"
        ></app-button>

        <app-button
          [label]="'Close'"
          [buttonStyleType]="'black'"
          (btnClick)="clickCancelButton()"
        ></app-button>
      </ng-container>
    </div>
  </mat-card-actions>
</mat-card>

