<div
  class="dropzone"
  [class.drop-area]="dropAreaClass"
>

  <input type="file"
   #file
   accept=".qif"
   [multiple]="false"
   style="display: none"
  />

  <div>
    {{label}}
  </div>

</div>
