<mat-card>
  <mat-card-content class="form" [formGroup]="form">
    <div class="form-field-area">
      <div class="left-side">

        <div class="row">
          <div class="form-field-wrapper">
            <app-input
              #formField
              class="form-field"
              label="Supplier"
              type="text"
              placeholder="Enter supplier"
              [formControl]="form.controls['supplier']"
              requiredValidator="true"
              [disableState]="modalMode === 'View'"
            ></app-input>
          </div>
        </div>

        <div class="row">
          <div class="form-field-wrapper">
            <app-select-opt-group-autocomplete
              [formControl]="form.controls['paidFrom']"
              label="Paid From"
              placeholder="Select paid from"
              requiredValidator="true"
              selectObjectValidator="true"
              [setList]="cashAccounts"
              [disableState]="modalMode === 'View'"
            ></app-select-opt-group-autocomplete>
          </div>
        </div>

        <ng-container *ngIf="form.get('paidFrom').value && !form.get('paidFrom').value.id && modalMode !== 'View'">

          <div class="row">
            <div class="form-field-wrapper">
              <ng-container *ngIf="modalMode !== 'View'">
                <div class="form-field-wrapper reimbursed-check">
                  <div class="form-field-label label">Does this need reimbursed?</div>
                  <mat-radio-group
                    class="radio-button"
                    [formControl]="form.controls['needsReimbursed']"
                    aria-label="Select an option"
                  >
                    <mat-radio-button [value]="true" [checked]="form.get('needsReimbursed').value">
                      Yes
                    </mat-radio-button>
                    <mat-radio-button [value]="false" [checked]="!form.get('needsReimbursed').value">
                      No
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </ng-container>
            </div>
          </div>

          <ng-container *ngIf="form.controls['needsReimbursed'].value">
            <div class="row">
              <div class="form-field-wrapper party">
                <app-input
                  #formField
                  class="form-field"
                  label="Who do you need reimbursed?"
                  type="text"
                  placeholder="John Doe"
                  [formControl]="form.controls['reimburseString']"
                  [disableState]="modalMode === 'View'"
                ></app-input>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="!form.controls['needsReimbursed'].value">
            <div class="row">
              <div class="form-field-wrapper party">
                <app-select-opt-group-autocomplete
                  [label]="'Who paid the expense for the business?'"
                  [formControl]="form.controls['reimburseAccount']"
                  [setList]="equityAccounts"
                  [placeholder]="'Select client'"
                  [disableState]="modalMode === 'View'"
                ></app-select-opt-group-autocomplete>
              </div>
            </div>
          </ng-container>

        </ng-container>

        <ng-container *ngIf="form.get('paidFrom').value && !form.get('paidFrom').value.id && modalMode === 'View'">
          <div class="row">
            <div class="form-field-label label">Does this need reimbursed?</div>
            <p class="view-text">{{form.get('needsReimbursed').value ? 'Yes' : 'No'}}</p>
          </div>
          <ng-container *ngIf="form.get('reimburseString').value">
            <div class="row">
              <div class="form-field-label label">Who do you need to reimburse?</div>
              <p class="view-text">{{form.get('reimburseString').value}}</p>
            </div>
          </ng-container>
          <ng-container *ngIf="form.get('reimburseAccount').value">
            <div class="row">
              <div class="form-field-label label">Who paid the expense for the business?</div>
              <p class="view-text">{{form.get('reimburseAccount').value.name}}</p>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div class="right-side">
        <div class="row">
          <div class="form-field-wrapper">
            <app-date-picker
              #formField
              class="form-field"
              label="Date"
              [formControl]="form.controls['date']"
              placeholder="dd/mm/yyyy"
              [isTextRight]="modalMode === 'View'"
              requiredValidator="true"
              [disableState]="modalMode === 'View'"
            ></app-date-picker>
          </div>
        </div>

        <div class="row">
          <div class="form-field-wrapper">
            <ng-container *ngIf="modalMode !== 'View' || !(modalMode === 'View' && !form.controls['dueDate'].value)">
              <app-date-picker
                #formField
                class="form-field"
                label="Due Date"
                [formControl]="form.controls['dueDate']"
                placeholder="dd/mm/yyyy"
                [isTextRight]="modalMode === 'View'"
                [disableState]="modalMode === 'View'"
              ></app-date-picker>
            </ng-container>
          </div>
        </div>

        <div class="row">
          <ng-container *ngIf="modalMode !== 'View' || !(modalMode === 'View' && !form.controls['ref'].value)">
            <div class="form-field-wrapper">
              <app-input
                #formField
                class="form-field"
                label="Reference"
                [formControl]="form.controls['ref']"
                type="text"
                [isTextRight]="modalMode === 'View'"
                [disableState]="modalMode === 'View'"
              ></app-input>
            </div>
          </ng-container>
        </div>

        <div class="row">
          <div class="form-field-wrapper amounts-are">
            <app-select-opt
              #formField
              class="form-field"
              label="Amounts are"
              requiredValidator="true"
              [formControl]="form.controls['taxMode']"
              [list]="taxModeList"
              (isDirty)="linesIsTouched = $event"
              [disableState]="modalMode === 'View'"
              [isTextRight]="modalMode === 'View'"
            ></app-select-opt>
          </div>
        </div>
      </div>
    </div>

    <div class="table-wrapper">
      <app-editable-table
        #editableTable
        [columns]="tableColumns"
        [requiredColumns]="requiredColumns"
        [modalType]="modalMode"
        [groupedAccounts]="groupedExpenseAccounts"
        [groupedAccountTypes]="groupedExpenseTypes"
        [class]="'line-table'"
        [formGroupRows$]="tableFormGroupRows$"
        [taxMode]="form.get('taxMode').value"
        (changedLines)="setTransactionLines($event)"
        (isDirty)="linesIsTouched = $event"
        (linesIsValid)="editableTableIsValid = $event"
      ></app-editable-table>

      <div class="under-table-row">
        <div class="left-side-container">
          <app-button
            *ngIf="modalMode !== 'View'"
            label="Add"
            buttonStyleType="add"
            (btnClick)="editableTable.addExpenseLineRow(); linesIsTouched = true"
          ></app-button>

          <ng-container *ngIf="currentExpense.hasManualLedger">
            <app-warning [text]="warningText"></app-warning>
          </ng-container>
        </div>

        <div class="amount-column">
          <ng-container *ngIf="getCurrentTaxMode() == 'Exclusive'">
            <div class="amount-field">
              <div class="label">Subtotal</div>
              <div class="value-area">${{ subtotal | number: '0.2-2' }}</div>
            </div>
          </ng-container>

          <ng-container *ngIf="getCurrentTaxMode() == 'Exclusive'">
            <div class="amount-field">
              <div class="label">Tax</div>
              <div class="value-area">
                ${{ tax | number: '0.2-2' }}
              </div>
            </div>
          </ng-container>

          <ng-container>
            <div class="amount-field amount-field--total">
              <div class="label">Total</div>
              <div class="value-area"> ${{ total | number: '0.2-2' }} </div>
            </div>
          </ng-container>

          <ng-container *ngIf="getCurrentTaxMode() === 'Inclusive'">
            <div class="amount-text">
              <span>Total includes tax of ${{ tax | number: '0.2-2' }}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

  </mat-card-content>
  <mat-card-actions class="action-buttons">
    <div class="action-buttons--left">
      <ng-container *ngIf="modalMode === 'Edit'">
        <app-button
          class="action-button"
          buttonStyleType="delete bg-white"
          [label]="'Delete'"
          (btnClick)="deleteButtonClick()"
        ></app-button>
      </ng-container>

      <ng-container *ngIf="modalMode === 'View' && currentExpense.invoice.status === 'Paid'">
        <div class="paid-label">Paid</div>
      </ng-container>
    </div>

    <div class="action-buttons--right">
      <ng-container *ngIf="modalMode === 'View'">
        <app-button
          class="action-button"
          buttonStyleType="primary"
          [label]="'Edit'"
          (btnClick)="editButtonClick()"
        ></app-button>
        <app-button
          class="action-button"
          buttonStyleType="black"
          label="Close"
          (btnClick)="cancelButtonClick()"
        ></app-button>
      </ng-container>

      <ng-container *ngIf="modalMode !== 'View'">
        <app-button
          class="action-button"
          buttonStyleType="primary"
          label="Save"
          [spinner]="(spinner$ | async) && !(isDeleting$ | async)"
          [disabled]="!isFormValid()"
          (btnClick)="saveButtonClick()"
        ></app-button>
        <app-button
          class="action-button"
          buttonStyleType="black"
          label="Cancel"
          (btnClick)="cancelButtonClick()"
        ></app-button>
      </ng-container>
    </div>
  </mat-card-actions>
</mat-card>
