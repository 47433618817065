<div class="auth-form forgot-password">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Forgot your password?
      </mat-card-title>
    </mat-card-header>

    <mat-card-content class="forgot-password" [formGroup]="form" (keydown.enter)="sendForm()">

      <div class="form-field-wrapper form-description">
        Enter the email address associated with your account
        and we’ll send you a link to reset your password.
      </div>

      <div class="form-field-wrapper forgot-password">
        <app-input
          label="Email address"
          placeholder="Enter your email address"
          formControlName="email"
          emailValidator="true"
          [type]="'email'"
          [hintSuccess]="'Your email looks good!'"
          [hintError]="'Please provide a valid email address.'"
        ></app-input>
      </div>

      <app-button
        label="Send Instructions"
        buttonStyleType="primary full"
        (btnClick)="sendForm()"
        [spinner]="spinnerStarted$ | async"
      ></app-button>

      <div class="card-bottom-text">Remember it?
        <mat-label class="nav" [routerLink]="['/auth/sign-in']">Sign in here</mat-label>
      </div>

    </mat-card-content>
  </mat-card>

  <div class="under-card-text mobile-hidden">
    <mat-label class="nav thin" (click)="openModalInfo('termsOfService')">Terms of Service</mat-label>
    <span class="nav thin">·</span>
    <mat-label class="nav thin" (click)="openModalInfo('privacyPolicy')">Privacy Policy</mat-label>
  </div>
</div>

